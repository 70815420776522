import { Route } from 'vue-router'

export const getUrlParams = (route: Route, params: string[]) => {
  const queryParams: { [key: string]: string | (string | null)[] } = {}
  params.forEach(param => {
    if (param in route.query) {
      queryParams[param] = route.query[param]
    }
  })
  return queryParams
}
