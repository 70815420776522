<template>
  <ElTableColumn
    :label="locale.label"
    width="200px"
  >
    <template slot-scope="{ row }">
      <ElPopover
        popper-class="campaigns-table-title-popper"
        placement="top-start"
        trigger="hover"
        :close-delay="0"
        :content="row.campaign.title"
      >
        <router-link
          :to="{name: 'edit-campaign', params: {campaignSlug: row.campaign.slug}}"
          slot="reference"
        >
          {{ row.campaign.title }}
        </router-link>
      </ElPopover>
    </template>
  </ElTableColumn>
</template>

<script>
import ElPopover from 'element/ElPopover'
import ElTableColumn from 'element/ElTableColumn'

export default {
  components: {
    ElTableColumn,
    ElPopover,
  },

  computed: {
    locale: ({ $locale }) => ({
      label: $locale('tables.columns.relatedCampaign'),
    }),
  },
}
</script>
