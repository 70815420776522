import { Api } from 'lib/api/instance'
import { getUrlParams } from 'lib/helpers/getUrlParams'
import parseSlug from 'lib/helpers/parseSlug'

const defaultCollection = {
  bootstrapped: false,
  perPage: 10,
  page: 1,
  total: 0,
  loading: false,
  items: [],
}

const getCollectionUrl = (kind, campaignType) => {
  if (kind === 'campaigns') {
    return `partner/campaigns/${campaignType}`
  }
  if (kind === 'groups') {
    return `partner/campaigns/${campaignType}/ad-set`
  }
  if (kind === 'creatives') {
    return 'partner/campaigns/brand_awareness/ad'
  }
}

const getQueryParams = (route, kind) => {
  switch (kind) {
    case 'campaigns':
      return getUrlParams(route, ['page', 'visible', 'advertiser', 'name', 'slug'])
    case 'groups':
      return getUrlParams(route, ['page', 'visible', 'platform', 'advertiser', 'name', 'slug'])
    case 'creatives':
      return getUrlParams(route, ['page', 'visible', 'advertiser', 'name', 'slug'])
  }
}

export default {
  namespaced: true,

  state: {
    campaigns: { ...defaultCollection },
    groups: { ...defaultCollection },
    creatives: { ...defaultCollection },
    visibleCampaignsSidebar: false,
    visibleGroupsSidebar: false,
    collectionsType: null,
    route: null,
    campaignType: null,
  },

  mutations: {
    setCollection (state, { kind, payload }) {
      state[kind].items = payload.data
      state[kind].total = payload.total
      state[kind].perPage = payload.per_page
      state[kind].bootstrapped = true
    },
    setCollectionPage (state, { kind, page }) {
      state[kind].page = page
    },
    toggleCampaignsSidebar (state, payload) {
      state.visibleCampaignsSidebar = payload
    },
    toggleGroupsSidebar (state, payload) {
      state.visibleGroupsSidebar = payload
    },
    setCollectionType (state, payload) {
      state.collectionsType = payload
    },
    setRoute (state, payload) {
      state.route = payload
    },
    setCampaignType (state, payload) {
      state.campaignType = payload
    },
  },

  actions: {
    async fetchCollection ({ commit, state }, kind) {
      const queryParams = getQueryParams(state.route, kind)
      const url = getCollectionUrl(kind, state.campaignType)

      const data = {
        page: state[kind].page,
        ...queryParams,
      }

      try {
        state[kind].loading = true
        commit('setCollectionType', kind)
        const res = await Api.get(url, data)
        commit('setCollection', {
          kind,
          payload: res,
        })
      }
      finally {
        state[kind].loading = false
      }
    },

    async changeStatus (store, slug) {
      const { kind } = parseSlug(slug)
      const url = `${getCollectionUrl(`${kind}s`, store.state.campaignType)}/status`
      const props = { slug }

      return await Api.post(url, props)
    },

    async fetchCampaigns ({ dispatch }) {
      dispatch('fetchCollection', 'campaigns')
    },
    async fetchGroups ({ dispatch }) {
      dispatch('fetchCollection', 'groups')
    },
    async fetchCreatives ({ dispatch }) {
      dispatch('fetchCollection', 'creatives')
    },
    setCollectionPage ({ commit, dispatch }, data) {
      commit('setCollectionPage', data)
      dispatch('fetchCollection', data.kind)
    },
    setCollectionType ({ commit }, collectionsType) {
      commit('setCollectionType', collectionsType)
    },
    setRoute ({ commit }, route) {
      commit('setRoute', route)
    },
    setCampaignType ({ commit }, campaignType) {
      commit('setCampaignType', campaignType)
    },

    showCampaignsSidebar ({ commit }) {
      commit('toggleCampaignsSidebar', true)
    },
    hideCampaignsSidebar ({ commit }) {
      commit('toggleCampaignsSidebar', false)
    },
    showGroupsSidebar ({ commit }) {
      commit('toggleGroupsSidebar', true)
    },
    hideGroupsSidebar ({ commit }) {
      commit('toggleGroupsSidebar', false)
    },
  },

  getters: {
    campaigns: ({ campaigns }) => campaigns,
    groups: ({ groups }) => groups,
    creatives: ({ creatives }) => creatives,
    collectionsType: ({ collectionsType }) => collectionsType,
    campaignType: ({ campaignType }) => campaignType,
    collectionLoading: ({ campaigns, groups, creatives }) => campaigns.loading || groups.loading || creatives.loading,
  },
}
