<template>
  <ElTableColumn
    :label="locale.label"
    min-width="150"
  >
    <template slot-scope="{ row }">
      <ElPopover
        popper-class="campaigns-table-partner-popper"
        placement="top-start"
        trigger="hover"
        :close-delay="0"
        :content="getPartnerName(row)"
      >
        <span slot="reference">
          {{ getPartnerName(row) }}
        </span>
      </ElPopover>
    </template>
  </ElTableColumn>
</template>

<script>
import ElPopover from 'element/ElPopover'
import ElTableColumn from 'element/ElTableColumn'

export default {
  components: {
    ElTableColumn,
    ElPopover,
  },

  computed: {
    locale: ({ $locale }) => ({
      label: $locale('tables.columns.advertiser'),
    }),
  },

  methods: {
    getPartnerName (row) {
      return row?.advertiser?.title || '—'
    },
  },
}
</script>

<style>
.campaigns-table-partner-popper {
  padding: 10px;
}
</style>
