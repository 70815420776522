import VueRouter from 'vue-router'

export const clearQueryParam = (router: VueRouter, params: string[]) => {
  params.forEach(param => {
    const query = Object.assign({}, router.currentRoute.query)
    delete query[param]
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    router.replace({ query }).catch(() => {})
  })
}
