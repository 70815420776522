<template>
  <ElTableColumn
    :label="locale.label"
    width="360px"
  >
    <template slot-scope="{ row }">
      {{ row.external_id || row.campaign?.external_id }}
    </template>
  </ElTableColumn>
</template>

<script>
import ElTableColumn from 'element/ElTableColumn'

export default {
  components: {
    ElTableColumn,
  },

  computed: {
    locale: ({ $locale }) => ({
      label: $locale('tables.columns.externalId'),
    }),
  },
}
</script>
