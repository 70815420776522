<template>
  <ElTableColumn
    fixed="right"
    width="60px"
  >
    <template #header>
      <span class="filter">
        <ElPopover
          placement="left-start"
        >
          <div slot="reference">
            <SvgIcon
              name="table-filter-icon"
              width="16px"
              height="16px"
              color="#666666"
            />
          </div>
          <div>
            <div class="filter__label">{{ locale.filterLabel }}</div>

            <div
              class="filter__item"
              v-for="(_, key) in columns"
              :key="key"
            >
              <ElCheckbox
                v-model="columns[key]"
                :name="key"
                @change="onChange"
              >
                {{ locale[key] }}
              </ElCheckbox>
            </div>
          </div>
        </ElPopover>
      </span>
    </template>
    <template slot-scope="{ row }">
      <TableActions
        :table-type="tableType"
        :date-start="row.start"
        :date-end="row.end"
        :slug="row.slug"
      />
    </template>
  </ElTableColumn>
</template>

<script>
import ElCheckbox from 'element/ElCheckbox'
import ElPopover from 'element/ElPopover'
import ElTableColumn from 'element/ElTableColumn'
import VueTypes from 'vue-types'

import TableActions from './TableActions'

export default {
  components: {
    ElTableColumn,
    ElPopover,
    ElCheckbox,
    TableActions,
  },

  props: {
    columns: VueTypes.object.isRequired,
    tableType: VueTypes.string,
  },

  computed: {
    locale: ({ $locale }) => $locale('tables.columns'),
  },

  methods: {
    onChange () {
      const filter = JSON.parse(window.localStorage.getItem('partner-table-filter') || '{}')

      filter.awareness = {
        [this.tableType]: this.columns,
      }

      window.localStorage.setItem('partner-table-filter', JSON.stringify(filter))
    },
  },
}
</script>

<style lang="scss" scoped>
  .filter {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    cursor: pointer;

    & > span {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 4px;
    }

    .el-form-item {
      margin-bottom: 0;
    }

    &__item {
      padding: 8px 0;
    }

    &__label {
      font-size: 10px;
      font-weight: 700;
      line-height: 12px;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      margin-bottom: 8px;
      color: $--color-dark-gray;
    }
  }

</style>
