<template>
  <ElTableColumn
    :label="locale.label"
    prop="end"
    width="130px"
  />
</template>

<script>
import ElTableColumn from 'element/ElTableColumn'

export default {
  components: {
    ElTableColumn,
  },

  computed: {
    locale: ({ $locale }) => ({
      label: $locale('tables.columns.endedAt'),
    }),
  },
}
</script>
