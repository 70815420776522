<template>
  <ElTableColumn
    :label="locale.label"
    width="120px"
  >
    <template slot-scope="{ row }">
      <span :class="{'status-pending': !row.published}">
        {{ row.published ? locale.complete : locale.pending }}
      </span>
    </template>
  </ElTableColumn>
</template>

<script>
import ElTableColumn from 'element/ElTableColumn'

export default {
  components: {
    ElTableColumn,
  },

  computed: {
    locale: ({ $locale }) => ({
      label: $locale('tables.columns.moderation'),
      pending: $locale('tables.columns.moderationStatus.pending'),
      complete: $locale('tables.columns.moderationStatus.complete'),
    }),
  },
}
</script>

<style lang="scss" scoped>
  .status-pending {
    color: $--color-warning;
  }
</style>
