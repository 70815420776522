<template>
  <ElTableColumn
    :label="locale.label"
    width="180px"
  >
    <template slot-scope="{ row }">
      <Progress
        v-if="row"
        :current="row.spent"
        :total="row.budget"
        :text="toCurrency(row)"
        :forecast="calcForecast(row)"
        :currency="getCurrency(row)"
      />
    </template>
  </ElTableColumn>
</template>

<script>
import Progress from 'components/Progress.vue'
import ElTableColumn from 'element/ElTableColumn'
import floatToCurrency from 'lib/filters/floatToCurrency'
import moment from 'moment'

export default {
  components: {
    ElTableColumn,
    Progress,
  },

  computed: {
    locale: ({ $locale }) => ({
      label: $locale('tables.columns.budget'),
    }),
    collectionsType: ({ $store }) => $store.getters['campaigns/collectionsType'],
  },

  methods: {
    getCurrency (row) {
      let currency = 'usd'
      if (this.collectionsType === 'campaigns') {
        currency = row?.advertiser?.wallet.currency.code || 'usd'
      }
      if (this.collectionsType === 'groups') {
        currency = row?.campaign?.advertiser.wallet.currency.code || 'usd'
      }
      if (this.collectionsType === 'creatives') {
        currency = row?.ad_set?.campaign?.advertiser.wallet.currency.code || 'usd'
      }
      return currency
    },
    toCurrency (row) {
      const sum = row.budget
      return floatToCurrency(sum, false, this.getCurrency(row))
    },
    calcForecast (row) {
      const currentDate = moment()
      const startDate = moment(row.start, 'DD.MM.YYYY')
      const endDate = moment(row.end, 'DD.MM.YYYY')

      const daysAfterStart = currentDate.diff(startDate, 'days') + 1 || 0
      const daysRemaining = endDate.diff(currentDate, 'days') + 1 || 0
      const duration = endDate.diff(startDate, 'days') + 1 || 0

      // console.dir({title: row.title, total_imps: row.total_impressions, daysAfterStart, daysRemaining, duration, 'Avg CPM': row.avg_cpm})

      if (daysRemaining === 0) {
        return 0
      }

      if (daysAfterStart === 1) {
        return row.totalBuget
      }

      const forecast = (row.impressions / daysAfterStart) * duration * row.avg_cpm / 1000

      if (forecast < row.spend) {
        return row.spend
      }

      if (forecast > row.budget) {
        return row.budget
      }

      return forecast
    },
  },
}
</script>
