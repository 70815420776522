<template>
  <ElTableColumn
    :label="locale.label"
    width="180px"
  >
    <template slot-scope="{ row }">
      <Progress
        v-if="row"
        :current="row.impressions"
        :total="row.total_impressions"
        :text="row.total_impressions | toRanks"
      />
    </template>
  </ElTableColumn>
</template>

<script>
import Progress from 'components/Progress.vue'
import ElTableColumn from 'element/ElTableColumn'

export default {
  components: {
    ElTableColumn,
    Progress,
  },

  computed: {
    locale: ({ $locale }) => ({
      label: $locale('tables.columns.impressions'),
    }),
  },
}
</script>
