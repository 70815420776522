<template>
  <ElTableColumn
    :label="locale.label"
    width="120px"
  >
    <template slot-scope="{ row }">
      {{ daysRemaining(row.end) }}
    </template>
  </ElTableColumn>
</template>

<script>
import ElTableColumn from 'element/ElTableColumn'
import moment from 'moment'

export default {
  components: {
    ElTableColumn,
  },

  computed: {
    locale: ({ $locale }) => ({
      label: $locale('tables.columns.daysRemaining'),
    }),
  },

  methods: {
    daysRemaining (end) {
      const endDate = moment(end, 'DD.MM.YYYY')
      const diff = endDate.diff(moment(), 'days') + 1

      return diff < 0 ? 0 : diff
    },
  },
}
</script>
