<template>
  <ElTableColumn
    :label="locale.label"
    width="250"
  >
    <template slot-scope="{ row }">
      <!-- <ElPopover
        popper-class="campaigns-table-title-popper"
        placement="top-start"
        trigger="hover"
        :close-delay="0"
        :open-delay="0"
        :content="getName(row)"
      > -->
      <router-link
        :to="getRouterLink(row)"
        slot="reference"
      >
        {{ getName(row) }}
      </router-link>
      <!-- </ElPopover> -->
    </template>
  </ElTableColumn>
</template>

<script>
import ElTableColumn from 'element/ElTableColumn'
// import ElPopover from 'element/ElPopover'
import parseSlug, { Kinds } from 'lib/helpers/parseSlug'

export default {
  components: {
    ElTableColumn,
    // ElPopover,
  },

  computed: {
    locale: ({ $locale }) => ({
      label: $locale('tables.columns.name'),
    }),
  },

  methods: {
    getName (row) {
      const { kind } = parseSlug(row.slug)

      if (kind === Kinds.CRV) {
        return row.title || `Creative ${row.slug}`
      }
      return row.title
    },
    getRouterLink (row) {
      const { kind } = parseSlug(row.slug)

      if (kind === Kinds.CMP) {
        return {
          name: 'edit-campaign',
          params: { campaignSlug: row.slug },
        }
      }
      else if (kind === Kinds.GRP) {
        return {
          name: 'edit-group',
          params: {
            campaignSlug: row.campaign.slug,
            groupSlug: row.slug,
          },
        }
      }
      else if (kind === Kinds.CRV) {
        return {
          name: 'edit-creative',
          params: {
            campaignSlug: row.ad_set.campaign.slug,
            groupSlug: row.ad_set.slug,
            creativeSlug: row.slug,
          },
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-popover__reference-wrapper {
    a {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>

<style>
.campaigns-table-title-popper {
  width: 250px;
  padding: 10px;
  margin-left: -10px;
  pointer-events: none;
  text-align: left;
  word-break: normal;
}
</style>
