import { defineStore } from 'pinia'

import { getAdvertisers } from 'lib/api/modules/partner/advertisers/advertisers'
import { IAdvertiser } from 'lib/types/partner/advertisers/advertiser'
import { getHoldings } from 'lib/api/modules/partner/advertisers/holdings'
import { IHolding } from 'lib/types/partner/advertisers/holding'

interface State {
  isFetchingData: boolean
  advertisers: null | IAdvertiser[]
  holdings: null | IHolding[]
}

export const useAdvertisersStore = defineStore({
  id: 'partner-advertisers',

  state: (): State => ({
    isFetchingData: false,
    advertisers: null,
    holdings: null,
  }),

  actions: {
    async fetchAdvertisers () {
      if (this.advertisers) return
      this.isFetchingData = true
      const res = await getAdvertisers()
      this.advertisers = res.data
      this.isFetchingData = false
    },

    async fetchHoldings () {
      if (this.holdings) return
      this.isFetchingData = true
      const res = await getHoldings()
      this.holdings = res.data
      this.isFetchingData = false
    },
  },
})
