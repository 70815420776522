<template>
  <ElTableColumn
    :label="locale.label"
    width="120px"
  >
    <template slot-scope="{ row }">
      {{ getFormat(row) }}
    </template>
  </ElTableColumn>
</template>

<script>
import ElTableColumn from 'element/ElTableColumn'

export default {
  components: {
    ElTableColumn,
  },

  computed: {
    locale: ({ $locale }) => ({
      label: $locale('tables.columns.format'),
    }),
  },

  methods: {
    getFormat (row) {
      if (row.ad_set) {
        return row.ad_set.format.title
      }
      return row.format.title
    },
  },
}
</script>
