<template>
  <ElTableColumn
    :label="locale.label"
    width="180px"
    prop="slug"
    fixed
  >
    <template slot-scope="{ row }">
      <router-link
        :to="getRouterLink(row)"
        slot="reference"
      >
        {{ row.slug }}
      </router-link>
    </template>
  </ElTableColumn>
</template>

<script>
import ElTableColumn from 'element/ElTableColumn'
import parseSlug, { Kinds } from 'lib/helpers/parseSlug'

export default {
  components: {
    ElTableColumn,
  },

  computed: {
    locale: ({ $locale }) => ({
      label: $locale('tables.columns.id'),
    }),
  },
  methods: {
    getRouterLink (row) {
      const { kind } = parseSlug(row.slug)

      if (kind === Kinds.CMP) {
        return {
          name: 'edit-campaign',
          params: { campaignSlug: row.slug },
        }
      }
      else if (kind === Kinds.GRP) {
        return {
          name: 'edit-group',
          params: {
            campaignSlug: row.campaign.slug,
            groupSlug: row.slug,
          },
        }
      }
      else if (kind === Kinds.CRV) {
        return {
          name: 'edit-creative',
          params: {
            campaignSlug: row.ad_set.campaign.slug,
            groupSlug: row.ad_set.slug,
            creativeSlug: row.slug,
          },
        }
      }
    },
  },
}
</script>

<style>
.campaigns-table-title-popper {
  width: 250px;
  padding: 10px;
  margin-left: -10px;
  pointer-events: none;
  text-align: left;
  word-break: normal;
}
</style>
