<template>
  <ElTableColumn
    :label="locale.label"
    width="80px"
  >
    <template slot-scope="{ row }">
      {{ row.ctr }}%
    </template>
  </ElTableColumn>
</template>

<script>
import ElTableColumn from 'element/ElTableColumn'

export default {
  components: {
    ElTableColumn,
  },

  computed: {
    locale: ({ $locale }) => ({
      label: $locale('tables.columns.ctr'),
    }),
  },
}
</script>
