<template>
  <ElDrawer
    :title="locale.title"
    :before-close="onClose"
    :visible="visible"
    direction="rtl"
    custom-class="groups-sidebar"
    ref="drawer"
    size="560px"
  >
    <div class="groups-sidebar__subtitle">
      {{ locale.description }}
    </div>

    <div
      v-loading="loading"
      class="groups-sidebar__content"
    >
      <ElTable
        :data="groups.data"
        @row-click="onRowClick"
      >
        <ElTableColumn
          :label="locale.name"
          prop="title"
        />
        <ElTableColumn
          :label="locale.slug"
          prop="slug"
        />
        <ElTableColumn
          :label="locale.relatedCampaign"
          prop="campaign.title"
        />
      </ElTable>

      <ElPagination
        layout="prev, pager, next"
        :prev-text="locale.prev"
        :next-text="locale.next"
        :page-size="groups.perPage"
        :current-page="groups.page"
        :total="groups.total"
        hide-on-single-page
        @current-change="onChangePage"
      />
    </div>
  </ElDrawer>
</template>

<script>
import ElDrawer from 'element/ElDrawer'
import ElPagination from 'element/ElPagination'
import ElTable from 'element/ElTable'
import ElTableColumn from 'element/ElTableColumn'
import loading from 'element/Loading'
import { Api } from 'lib/api/instance'

export default {
  components: {
    ElDrawer,
    ElTable,
    ElTableColumn,
    ElPagination,
  },

  directives: { loading },

  data: () => ({
    loading: true,
    groups: {},
  }),

  computed: {
    locale: ({ $locale, $rootLocale }) => ({
      next: $rootLocale('pagination.next'),
      prev: $rootLocale('pagination.prev'),
      name: $locale('tables.columns.name'),
      slug: $locale('tables.columns.id'),
      relatedCampaign: $locale('tables.columns.relatedCampaign'),
      title: $locale('newCreative.title'),
      description: $locale('newCreative.description'),
    }),
    visible: ({ $store }) => $store.state.campaigns.visibleGroupsSidebar,
  },

  watch: {
    visible (val) {
      if (val) {
        this.fetchGroups()
      }
    },
  },

  methods: {
    onClose () {
      this.$store.dispatch('campaigns/hideGroupsSidebar')
    },
    onChangePage (page) {
      this.fetchGroups(page)
    },
    onRowClick (group) {
      this.$router.push({
        name: 'create-creative',
        params: {
          groupSlug: group.slug,
          campaignSlug: group.campaign.slug,
        },
      })
    },
    async fetchGroups (page = 1) {
      try {
        this.loading = true
        this.groups = await Api.get('partner/campaigns/ad-set', { page })
      }
      finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.groups-sidebar {
  &__subtitle {
    margin-top: -20px;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid $--color-lightest-gray;
  }

  &__content {
    ::v-deep .el-table__row {
      cursor: pointer;
    }
  }
}
</style>
