<template>
  <div class="headline-2 table-none">
    {{ text }}
  </div>
</template>

<script>
export default {
  props: {
    text: VueTypes.string,
  },
}
</script>

<style lang="scss" scoped>
.table-none {
  padding-top: 167px;
  text-align: center;
  color: $--color-lightest-gray;
}
</style>
